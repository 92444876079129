import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ArnAdminComponent } from './arn-admin.component';
import { RouterModule } from '@angular/router';
import { arnRoutes } from './arn.routes';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCommonModule } from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { ArnServerComponent } from './server/arn-server.component';
import { ArnProjectComponent } from './server/project/arn-project.component';
import { ArnConfigDetailsComponent } from './server/project/config/details/arn-config-details.component';
import { ArnServerAddComponent } from './server/add/arn-server-add.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ArnConfigComponent } from './server/project/config/arn-config.component';
import { ArnConfigClientComponent } from './server/project/config/details/client/arn-config-client.component';
import { ArnConfigClientAuthComponent } from './server/project/config/details/client/auth/arn-config-client-auth.component';
import { MatSelectModule } from '@angular/material/select';
import { ArnDataComponent } from './server/project/data/arn-data.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  declarations: [
    ArnAdminComponent,
    ArnServerComponent,
    ArnProjectComponent,
    ArnConfigComponent,
    ArnConfigDetailsComponent,
    ArnConfigClientComponent,
    ArnConfigClientAuthComponent,
    ArnDataComponent,
    ArnServerAddComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ClipboardModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatTreeModule,
    MatFormFieldModule,
    MatTabsModule,
    MatIconModule,
    MatCommonModule,
    MatExpansionModule,
    RouterModule.forRoot(arnRoutes, { initialNavigation: 'enabledBlocking' }),
    MatButtonToggleModule,
  ],
  providers: [],
  bootstrap: [ArnAdminComponent],
})
export class ArnModule {}
