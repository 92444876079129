export class UrlUtil {
  static join(...parts: string[]) {
    /* This function takes zero or more strings, which are concatenated
    together to form a path or URL, which is returned as a string. This
    function intelligently adds and removes slashes as required, and is
    aware that `file` URLs will contain three adjacent slashes. */

    const [first, last, slash] = [0, parts.length - 1, '/'];

    const matchLeadingSlash = new RegExp('^' + slash);
    const matchTrailingSlash = new RegExp(slash + '$');

    parts = parts.map(function (part, index) {
      if (index === first && part === 'file://') {
        return part;
      }

      if (index > first) {
        part = part.replace(matchLeadingSlash, '');
      }

      if (index < last) {
        part = part.replace(matchTrailingSlash, '');
      }

      return part;
    });

    return parts.join(slash);
  }

  static extname(fileName: string): string {
    const dotPos = fileName.lastIndexOf('.');
    return fileName.substring(dotPos);
  }
}
