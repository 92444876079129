import { ArnLogger, AuthContextPersistence } from '@arianee/arn-types';
import { ArnClientError } from '../../ArnClientError';
import { AuthContextPersistStrategy } from './AuthContextPersistStrategy';
import { AuthContextPersistStrategyFactory } from './AuthContextPersistStrategyFactory';
import { NopStorage } from './NopStorage';
import { StorageAuthContextPersistStrategy } from './StorageAuthContextPersistStrategy';

export class DefaultAuthContextPersistStrategyFactory
  implements AuthContextPersistStrategyFactory
{
  protected static readonly nopStorage = new NopStorage();

  protected log: ArnLogger;

  constructor(parentLog: ArnLogger, protected prefix: string) {
    this.log = parentLog.fork('DefaultAuthContextPersistStrategyFactory');
  }

  create(persist: AuthContextPersistence): AuthContextPersistStrategy {
    const storages: Storage[] = [];
    const storageNames: string[] = persist
      ? persist === true
        ? ['localStorage']
        : (persist as string).split(',')
      : ['nopStorage'];
    for (const storageName of storageNames) {
      switch (storageName) {
        case 'localStorage':
          storages.push(localStorage);
          break;
        case 'sessionStorage':
          storages.push(sessionStorage);
          break;
        case 'nopStorage':
          storages.push(DefaultAuthContextPersistStrategyFactory.nopStorage);
          break;
        default:
          throw new ArnClientError(
            'Unsupported auth context persistence storage: ' + storageName
          );
      }
    }
    return new StorageAuthContextPersistStrategy(
      this.log,
      storages,
      this.prefix
    );
  }
}
