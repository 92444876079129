import {
  ArnAdminAddServerMessages,
  ArnAdminProjectMessages,
  ArnAdminToolMessages,
} from './ArnAdminToolMessages';

const addServer: ArnAdminAddServerMessages = {
  button: {
    label: 'Ajouter un serveur',
  },
  dialog: {
    title: 'Ajouter un serveur ARN',
    serverUrl: {
      label: 'URL du serveur',
      whenEmpty:
        "Veuillez fournir l'URL du serveur ARN que vous souhaitez administrer",
      whenInvalid: 'URL invalide',
    },
    apiKey: {
      label: 'Clé API',
      whenEmpty: `Vous ne pouvez administrer sans une clé de serveur ou de projet`,
      whenInvalid: 'Clé API invalide',
    },
    add: { label: 'Ajouter' },
    cancel: { label: 'Annuler' },
  },
};

const project: ArnAdminProjectMessages = {
  label: 'Projet',
  delete: {
    confirm: (project: string, server: string) =>
      `Supprimer le projet "${project}" de ${server} ?`,
    confirmed: (project: string) => `Projet "${project}" supprimé.`,
  },
  config: {
    label: 'Configuration du projet',
    filter: 'Filtrer',
    predefinedFilters: [
      { filter: '', label: 'Tout' },
      { filter: 'client/auth', label: 'Auth client' },
      { filter: 'auth', label: 'Auth serveur' },
      { filter: 'data', label: 'Stockage données' },
      { filter: 'i18n', label: 'Stockage traductions' },
      { filter: 'condition', label: 'Stockage conditions' },
      { filter: 'nmp', label: 'NMP' },
      { filter: 'spkz', label: 'SPKZ' },
      { filter: 'track', label: 'Suivi clients' },
    ],
    fetch: 'Récupérer',
    update: 'Enregistrer',
    updated: 'Configuration du projet enregistrée',
  },
  data: {
    label: 'Données du projet',
    filter: 'Filtrer',
    predefinedFilters: [
      { filter: '', label: 'Tout' },
      { filter: 'features', label: 'Fonctionnalités' },
      { filter: 'i18n', label: 'Traductions' },
      { filter: 'condition', label: 'Conditions' },
    ],
    fetch: 'Récupérer',
    update: 'Enregistrer',
    updated: 'Données du projet enregistrées',
  },
};

export default class ArnAdminToolMessages_fr implements ArnAdminToolMessages {
  root = {
    addServer,
    filterServerUrl: 'Filtrer URL',
    project,
    servers: (n: number, total: number) =>
      n <= 0
        ? ''
        : n +
          '/' +
          (total < 1 ? '' : total === 1 ? '1 serveur' : total + ' serveurs'),
    server: {
      projects: (n: number) =>
        n < 1 ? 'Aucun projet' : n === 1 ? '1 projet' : n + ' projets',
      projectsLoading: 'Chargement des projets...',
    },
  };
}
