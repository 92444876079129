<mat-toolbar>
  <mat-panel-title>ARN Admin</mat-panel-title>
  <mat-form-field *ngIf="servers.length > 0" appearance="fill" class="filter" >
    <mat-label>{{messages.root.filterServerUrl}}</mat-label>
    <input matInput type="search" [(ngModel)]="filter" (input)="refresh()">
  </mat-form-field>
  <mat-panel-description>{{ messages.root.servers(displayedServers.length, servers.length) }}</mat-panel-description>
</mat-toolbar>
<mat-accordion *ngFor="let server of displayedServers">
  <arn-server [server]="server" (removed)="removeServer(server)" (projectFound)="projectFound($event)"></arn-server>
</mat-accordion>
<mat-action-row>
  <button mat-button color="primary" (click)="addServerClicked()">
    <mat-icon aria-hidden="false" aria-label="Add server" fontIcon="add_circle"></mat-icon>
    {{messages.root.addServer.button.label}}
  </button>
</mat-action-row>
