<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title><code>auth</code></mat-panel-title>
      <mat-panel-description>Authentication</mat-panel-description>
    </mat-expansion-panel-header>
    <pre>{{config?.auth | json}}</pre>
  </mat-expansion-panel>
  <arn-config-client [config]="config?.client"></arn-config-client>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title><code>data</code></mat-panel-title>
      <mat-panel-description>Project data storage</mat-panel-description>
    </mat-expansion-panel-header>
    <pre>{{config?.data | json}}</pre>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title><code>i18n</code></mat-panel-title>
      <mat-panel-description>Project internationalization</mat-panel-description>
    </mat-expansion-panel-header>
    <pre>{{config?.i18n | json}}</pre>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title><code>nmp</code></mat-panel-title>
      <mat-panel-description>NFT Management Platform</mat-panel-description>
    </mat-expansion-panel-header>
    <pre>{{config?.nmp | json}}</pre>
  </mat-expansion-panel>
</mat-accordion>
