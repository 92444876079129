import { Web3ModalConfig } from '@web3modal/html';
import { Chain } from '@wagmi/core';

export enum Web3ModalV2DesktopBehaviorMode {
  ArianeeOrWalletConnect = 'ArianeeOrWalletConnect',
  ArianeeOnly = 'ArianeeOnly',
  WalletConnectOnly = 'WalletConnectOnly',
}

export interface Web3ModalV2DesktopBehaviorOpts {
  mode: Web3ModalV2DesktopBehaviorMode;

  /**
   * The URL to go to even if the user doesn’t have a wallet yet
   */
  universalLink?: string;

  /**
   * In case of ArianeeOrWalletConnect mode,
   * the url of the logo to displayed in the primary (Arianee) connection button.
   */
  primaryLogoUrl?: string;

  /**
   * In case of ArianeeOrWalletConnect mode,
   * the url of the logo to displayed in the primary (Arianee) connection button,
   * when in dark mode (will default to non-dark mode otherwise).
   */
  primaryLogoUrl_dark?: string;

  /**
   * In case of ArianeeOrWalletConnect mode,
   * the text displayed under the primary (Arianee) connection button.
   */
  primaryText?: string;

  /**
   * In case of ArianeeOrWalletConnect mode,
   * the text displayed under the secondary (wallet connect) connection button.
   */
  secondaryText?: string;
}

// TODO: Don't extend it Web3ModalConfig as this is confusing. Make a clearer distinction of ARN-specific properties.
export interface Web3ModalV2ConnectorOpts extends Web3ModalConfig {
  chains: Chain[];
  wcVersion?: 2;
  desktopBehavior: Web3ModalV2DesktopBehaviorOpts;
  removeDesktopWallets?: boolean;
  storagePrefix?: string;

  /**
   * The time to wait before successful opening of the modal.
   */
  timeout?: number;

  /**
   * true by default
   */
  autoConnect?: boolean;
}
