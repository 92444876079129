<mat-expansion-panel *ngIf="config">
  <mat-expansion-panel-header>
    <mat-panel-title><code>auth</code></mat-panel-title>
    <mat-panel-description>Client auth configuration</mat-panel-description>
  </mat-expansion-panel-header>
  <mat-form-field appearance="fill">
    <mat-label>Connector type</mat-label>
    <mat-select [(value)]="config.connectorType">
      <mat-option *ngFor="let connectorType of ConnectorType" [value]="connectorType">{{connectorType}}</mat-option>
    </mat-select>
  </mat-form-field>
  <pre>{{config | json}}</pre>
</mat-expansion-panel>
