export class UrlUtil {
  static build(
    url: string | undefined,
    defaultUrl: string,
    defaultBase?: string
  ): string {
    let fullUrl;
    try {
      fullUrl = new URL(url!);
    } catch (e) {
      const path = url || defaultUrl;
      fullUrl = new URL(defaultBase ? UrlUtil.join(defaultBase, path) : path);
    }
    return fullUrl.toString();
  }

  static join(urlStart: string, urlEnd: string) {
    const end = urlEnd.startsWith('/') ? urlEnd.substring(1) : urlEnd;
    return urlStart.endsWith('/') ? urlStart + end : `${urlStart}/${end}`;
  }
}
