import { DataMigration, DataMigrationOutput } from './DataMigration';
import { ArnLogger } from '@arianee/arn-types';

export class DataMigrator<I = object, O = I> {
  constructor(protected logger: ArnLogger) {}

  async migrate(
    migrationName: string,
    data: I[]
  ): Promise<DataMigrationOutput<O>[]> {
    const { default: Command } = await import(migrationName);
    const migration = new Command(this.logger) as DataMigration<I, O>;
    return migration.executeAll(data);
  }
}
