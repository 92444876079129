<mat-expansion-panel (opened)="expanded()" hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title><code>arn_config</code></mat-panel-title>
    <mat-panel-description>{{admin!.context.messages.project.config.label}}</mat-panel-description>
    <button mat-icon-button [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation();" aria-label="Configuration menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button (click)="export()" mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Export configuration" fontIcon="download"></mat-icon>
        Export
      </button>
      <button mat-menu-item [matMenuTriggerFor]="exportMenu">
        <mat-icon aria-hidden="false" aria-label="Import configuration" fontIcon="upload"></mat-icon>
        Import
      </button>
    </mat-menu>
    <mat-menu #exportMenu="matMenu">
      <button (click)="import()" mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Import configuration" fontIcon="description"></mat-icon>
        File...
      </button>
    </mat-menu>
  </mat-expansion-panel-header>
  <div class="predefined-filters">
    <mat-button-toggle-group [(ngModel)]="predefinedFilter" (change)="fetchPredefined($event)"
      aria-label="Predefined filter">
      <mat-button-toggle *ngFor="let predefined of predefinedFilters"
        [value]="predefined.filter">{{predefined.label}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-form-field appearance="fill">
    <mat-label>{{admin!.context.messages.project.config.filter}}</mat-label>
    <input matInput type="search" [(ngModel)]="filter" (change)="fetch()">
  </mat-form-field>
  <button mat-button color="primary" (click)="fetch()" align="end"
    aria-label="Fetch">{{admin!.context.messages.project.config.fetch}}
  </button>
  <mat-tab-group *ngIf="details" animationDuration="0ms" (selectedTabChange)="tabChange($event)">
    <mat-tab label="Properties">
      <arn-config-details [config]="config || undefined"></arn-config-details>
    </mat-tab>
    <mat-tab label="Raw">
      <div class="full-json-editor"></div>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="!details" class="full-json-editor"></div>
  <div mat-dialog-actions align="end">
    <button mat-button [disabled]="updating || !jsonOk" color="primary" (click)="update()"
      align="right"
      aria-label="Update config">
      <span *ngIf="updating">
        <mat-spinner [diameter]="22"></mat-spinner>
        Updating...
      </span> <span *ngIf="!updating">
       {{admin!.context.messages.project.config.update}}
      </span>
    </button>
  </div>
</mat-expansion-panel>
