import { BehaviorSubject, Observable } from 'rxjs';
import { ArnAuthStatus, ArnConnectionStatus } from './ArnAuthStatus';
import { ArnClientError } from '../ArnClientError';
import {
  AnyArianeeJWT,
  ArnConnector,
  ArnConnectorOptions,
  ArnConnectorType,
  ArnLogger,
  ArnValidAuthConfig,
  Base64Util,
  IArnAuthContext,
  JWTHeader,
  ObjectUtil,
} from '@arianee/arn-types';
import {
  ethers,
  TransactionRequest,
  TypedDataDomain,
  TypedDataField,
} from 'ethers';
import { UserRejectionError } from '../UserRejectionError';
import { AuthContextPersistStrategy } from './persist';
import { AlreadyConnectedError } from './AlreadyConnectedError';

export class NoAuthContextError extends ArnClientError {
  constructor() {
    super('No auth context');
  }
}

export class NoConnectorError extends ArnClientError {
  constructor() {
    super('ARN connector expected');
  }
}

export class ArnAuthContext implements IArnAuthContext {
  static readonly AAT_JWT_HEADER: JWTHeader = { typ: 'JWT', alg: 'ETH' };

  static readonly DEFAULT_STATUS: ArnAuthStatus = {
    connectionStatus: ArnConnectionStatus.disconnected,
    address: undefined,
    signature: undefined,
    aat: undefined,
    connectorType: ArnConnectorType.Web3ModalV2,
    connectorOpts: {},
  };

  protected _status$: BehaviorSubject<ArnAuthStatus>;

  readonly id: string;

  get status$(): Observable<ArnAuthStatus> {
    return this._status$;
  }

  get hasConnector(): boolean {
    return !!this._connector;
  }

  get connector(): ArnConnector {
    if (!this._connector) {
      throw new NoConnectorError();
    }
    return this._connector;
  }

  protected static instanceCount = 0;

  protected readonly log: ArnLogger;

  protected _status: ArnAuthStatus;

  constructor(
    parentLog: ArnLogger,
    readonly config: ArnValidAuthConfig,
    readonly _connector: ArnConnector<ArnConnectorOptions> | undefined,
    readonly persistStrategy: AuthContextPersistStrategy,
    status?: ArnAuthStatus
  ) {
    ArnAuthContext.instanceCount++;
    this.id = `ArnAuthContext-${ArnAuthContext.instanceCount}`;
    this.log = parentLog.fork(this.id);
    this._status = !status ? ArnAuthContext.DEFAULT_STATUS : status;
    this._status$ = new BehaviorSubject<ArnAuthStatus>(this._status);
  }

  async signAAT(
    jwtPayload: AnyArianeeJWT,
    message?: string,
    sep?: string,
    jwtHeader: JWTHeader = ArnAuthContext.AAT_JWT_HEADER
  ): Promise<string> {
    const headerAsString = JSON.stringify(jwtHeader);
    const payloadAsString = JSON.stringify(jwtPayload);
    let toSign = `${headerAsString}.${payloadAsString}`;
    let toBuf = headerAsString;
    if (message) {
      const messageWithSep = sep ? message + sep : message;
      toSign = messageWithSep + toSign;
      toBuf = messageWithSep + toBuf;
    }
    const payloadB64 = Base64Util.toBase64(payloadAsString);
    const fingerprint = `${Base64Util.toBase64(toBuf)}.${payloadB64}`;
    const signature = await this.signMessage(toSign);
    return `${fingerprint}.${signature}`;
  }

  async signMessage(message: string) {
    return this.connector.signMessage(message);
  }

  async signTypedData(
    domain: TypedDataDomain,
    types: Record<string, Array<TypedDataField>>,
    value: Record<string, any>
  ) {
    return this.connector.signTypedData(domain, types, value);
  }

  async signTransaction(transaction: TransactionRequest) {
    return this.connector.signTransaction(transaction);
  }

  async sendTransaction(transaction: TransactionRequest) {
    return this.connector.sendTransaction(transaction);
  }

  async sendUncheckedTransaction(transaction: TransactionRequest) {
    return this.connector.sendUncheckedTransaction(transaction);
  }

  getAddress() {
    return this.status.address;
  }

  async getChainId() {
    return this.connector.getChainId();
  }

  async switchChain(chainId: bigint) {
    this.log.debug('switchChain', chainId);
    await this.connector.switchChain(chainId);
  }

  async disconnect() {
    this.log.debug('disconnect');
    if (this.hasConnector) {
      await this.connector.disconnect();
    }
    this.status = {
      ...this._status,
      connectionStatus: ArnConnectionStatus.disconnected,
      signature: undefined,
      aat: undefined,
    };
  }

  set status(newStatus: ArnAuthStatus) {
    this.log.debug('set status', newStatus);
    this._status = newStatus;
    this.persistStrategy.setStatus(newStatus);
    this._status$.next(newStatus);
  }

  get status(): ArnAuthStatus {
    return this._status;
  }

  static asSet(context: ArnAuthContext | undefined): ArnAuthContext {
    return ObjectUtil.asSet(context, () => new NoAuthContextError());
  }

  static asConnected(context: ArnAuthContext | undefined): ArnAuthContext {
    const ctx = ArnAuthContext.asSet(context);
    if (ctx.isDisconnected()) {
      throw new ArnClientError('Not connected');
    }
    return ctx;
  }

  static asDisconnected(
    context: ArnAuthContext | undefined
  ): ArnAuthContext | undefined {
    if (context?.isConnected()) {
      throw new AlreadyConnectedError(
        'but connection status is: ' + context?._status.connectionStatus
      );
    }
    return context;
  }

  static asNotAuthenticated(
    context: ArnAuthContext | undefined
  ): ArnAuthContext | undefined {
    if (context?.isAuthenticated()) {
      throw new ArnClientError('Expected to be not authenticated');
    }
    return context;
  }

  readonly isConnected = () => !this.isDisconnected();

  readonly isAuthenticated = () =>
    this._status.connectionStatus === ArnConnectionStatus.authenticated;

  readonly isDisconnected = () =>
    this._status.connectionStatus === ArnConnectionStatus.disconnected;

  static getSignerAddress(signature: string, message: string): string {
    return ethers.verifyMessage(message, signature);
  }

  async sign() {
    this.log.debug('sign()');
    const beforeSigning = { ...this.status };
    let retry: boolean;
    do {
      retry = false;
      try {
        const callbackModal =
          this.status.connectionStatus === ArnConnectionStatus.signing
            ? undefined
            : this.config.beforeSign || this.config.signCallback;
        this.status = {
          ...this.status,
          address: this.getAddress(),
          connectionStatus: ArnConnectionStatus.signing,
        };
        const message = this.status.message;
        if (callbackModal) {
          const userAction = await callbackModal(this);
          switch (userAction) {
            case false:
              throw new UserRejectionError('User rejected sign');
            case true:
              break;
          }
        }
        const connector = this.connector;
        const isMessageEmpty =
          message === undefined ||
          message === null ||
          message === '' ||
          message.trim() === '';
        // Pass an empty string if the message is undefined.
        // Some connectors (e.g. ArianeeCoreConnector) use dynamic messages for authentication and therefore cannot be pre-defined.
        const signature = await connector.signMessage(
          !isMessageEmpty ? message : ''
        );
        const authMessage = !isMessageEmpty
          ? message
          : await connector.getAuthMessage();
        const address = ArnAuthContext.getSignerAddress(signature, authMessage);
        if (address !== this.status.address) {
          throw new ArnClientError(
            `Signer address ${address} is different from connected wallet address ${this.status.address}`
          );
        }
        this.status = {
          ...this.status,
          address,
          message: authMessage,
          signature,
          connectionStatus: ArnConnectionStatus.authenticated,
        };
      } catch (e) {
        this.log.error('error during sign', e);
        this.status = beforeSigning;
        throw e;
      }
    } while (retry);
  }

  async connect() {
    try {
      const uncheckedAddress = await this.connector.connect();
      this.log.debug('UncheckedAddressResolved', uncheckedAddress);
      const conStatus = this.connector.status;
      this.status = {
        ...this.status,
        connectionStatus:
          conStatus === 'connected'
            ? ArnConnectionStatus.connected
            : conStatus === 'disconnected'
            ? ArnConnectionStatus.disconnected
            : ArnConnectionStatus.connecting,
        address: await this.connector.getAddress(),
      };
    } catch (e) {
      await this.disconnect();
      throw e;
    }
  }
}
