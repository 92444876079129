import { JsonRpcProvider } from 'ethers';

// TODO: Duplicate of ChainId
export enum Chains {
  Ethereum = 1,
  Goerli = 5,
  Polygon = 137,
  Mumbai = 80001,
}

export const RPC_URLS: { [key: number]: string } = {
  [Chains.Ethereum]:
    'https://mainnet.infura.io/v3/e6f1679793874c6ca32b4a8bbab00939',
  [Chains.Goerli]:
    'https://eth-goerli.g.alchemy.com/v2/6zfo6QJunf3kz88QSOgnopcIgeUkYKxr',
};

export const getJsonRpcProvider = (chainId: number) => {
  const rpcUrl = RPC_URLS[chainId];
  if (!rpcUrl) {
    throw new Error(`Cannot resolve rpcUrl for chainId: ${chainId}`);
  }
  return new JsonRpcProvider(rpcUrl);
};
