<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title><code>client</code></mat-panel-title>
    <mat-panel-description>Client configuration</mat-panel-description>
  </mat-expansion-panel-header>
  <arn-config-client-auth [config]="config?.auth"></arn-config-client-auth>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title><code>data</code></mat-panel-title>
      <mat-panel-description>Client data configuration</mat-panel-description>
    </mat-expansion-panel-header>
    <pre>{{config?.data | json}}</pre>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title><code>i18n</code></mat-panel-title>
      <mat-panel-description>Client internationalization configuration</mat-panel-description>
    </mat-expansion-panel-header>
    <pre>{{config?.i18n | json}}</pre>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title><code>track</code></mat-panel-title>
      <mat-panel-description>Client tracking configuration</mat-panel-description>
    </mat-expansion-panel-header>
    <pre>{{config?.track | json}}</pre>
  </mat-expansion-panel>
</mat-expansion-panel>
