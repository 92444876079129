export enum ArnConnectorType {
  /**
   * Supports several web3 providers, including Wallet Connect, MetaMask, etc. (v2)
   */
  Web3ModalV2 = 'Web3ModalV2',

  DefaultWeb3ModalV2 = 'DefaultWeb3ModalV2',

  /**
   * Arianee Access Token.
   *
   * @see ArnToken
   */
  AAT = 'AAT',
  /**
   * Arianee Core.
   */
  ArianeeCore = 'ArianeeCore',

  /**
   * Supports several web3 providers, including Ledger iframe provider.
   */
  Ledger = 'Ledger',

  WebAuthn = 'WebAuthn',
}
