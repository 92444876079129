export type ArnLogMethod = {
  (message?: any, ...optionalParams: any[]): void;
  (...data: any[]): void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const NOP = () => {};

export interface ArnLogger {
  /**
   * The logger name, used as a prefix for logs.
   */
  name: string;

  info: ArnLogMethod;
  debug: ArnLogMethod;
  warn: ArnLogMethod;
  error: ArnLogMethod;

  /**
   * Enter a logging sub-context.
   *
   * @param subName The name of the sub-context.
   * @param nested If the subName must be appended to parent name (default).
   * @return The child logger.
   */
  fork(subName: string, nested?: boolean): ArnLogger;
}
