import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { EditorView } from '@codemirror/view';
import { basicSetup } from 'codemirror';
import { json, jsonParseLinter } from '@codemirror/lang-json';
import { diagnosticCount, linter, lintGutter } from '@codemirror/lint';
import { autocompletion, CompletionContext } from '@codemirror/autocomplete';
import { syntaxTree } from '@codemirror/language';
import {
  AdminObjectUtil,
  ArnProjectAdmin,
  ArnProjectValidConfigurationValue,
  PredefinedFilter,
} from '@arianee/arn-admin-client';
import { ObjectUtil } from '@arianee/arn-types';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'arn-config',
  templateUrl: './arn-config.component.html',
  styleUrls: ['./arn-config.component.scss'],
})
export class ArnConfigComponent implements OnInit {
  @Input()
  admin: ArnProjectAdmin | undefined;

  @Input()
  updating = false;

  @Input()
  id?: string;

  @Output()
  updated = new EventEmitter<ArnProjectValidConfigurationValue>();

  config: ArnProjectValidConfigurationValue | undefined;
  editedConfigStr = '';
  editedConfig: object = {};
  jsonOk = false;
  details = false;
  filter = '';
  predefinedFilter = '';
  protected latestFilter = '';

  protected editorView?: EditorView;
  predefinedFilters: PredefinedFilter[] = [];

  constructor(protected snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.predefinedFilters =
      this.admin?.context.messages.project.config.predefinedFilters || [];
  }

  async fetch() {
    const foundPredefined = this.predefinedFilters.find(
      (predefinedFilter) =>
        (predefinedFilter.filter.length > 0 &&
          this.filter.startsWith(predefinedFilter.filter)) ||
        predefinedFilter.filter === this.filter
    );
    this.predefinedFilter = foundPredefined ? foundPredefined.filter : '_';
    this.config = await this.admin?.get();
    if (this.config) {
      const objectFilter = AdminObjectUtil.dotPathFrom('/', this.filter);
      this.editedConfig = ObjectUtil.find(this.config, objectFilter).value;
      this.editedConfigStr = JSON.stringify(this.editedConfig, null, 2);
      this.latestFilter = this.filter;
      if (!this.details) {
        this.loadEditor();
      }
    }
    return this.config;
  }

  update() {
    if (this.config) {
      try {
        this.editedConfig = JSON.parse(this.getEditedConfig());
        const objectFilter = AdminObjectUtil.dotPathFrom(
          '/',
          this.latestFilter
        );
        AdminObjectUtil.set(this.config, objectFilter, this.editedConfig);
        this.updated.emit(this.config);
      } catch (e) {
        this.snackBar.open((e as Error).toString(), 'Dismiss');
      }
    }
  }

  async expanded() {
    if (!this.config) {
      this.config = await this.fetch();
    }
  }

  protected getEditedConfig(): string {
    if (this.editedConfigStr) {
      this.editedConfigStr = this.editorView?.state.doc.toString() || '';
    } else {
      this.editedConfigStr = JSON.stringify(this.config, null, 2);
    }
    return this.editedConfigStr;
  }

  export() {
    const link = document.createElement('a');
    link.href = `data:text/plain;charset=utf-8,\uFEFF${encodeURIComponent(
      this.getEditedConfig()
    )}`;
    link.download = this.id + `-config.json`;
    link.click();
  }

  tabChange(event: MatTabChangeEvent) {
    if (event.index === 1) {
      this.loadEditor();
    }
  }

  protected completion(context: CompletionContext) {
    let node: any = syntaxTree(context.state).resolveInner(context.pos, -1);
    let str = '';
    while (node !== null) {
      str = (node as any).context.buffer.buffer + '.' + str;
      node = node.parent;
    }
    console.log(str);
    const word = context.matchBefore(/\w*/);
    if (!word || (word.from == word.to && !context.explicit)) {
      return null;
    }
    return {
      from: word.from,
      options: [
        { label: 'match', type: 'keyword' },
        { label: 'hello', type: 'variable', info: '(World)' },
        { label: 'magic', type: 'text', apply: '⠁⭒*.✩.*⭒⠁', detail: 'macro' },
      ],
    };
  }

  protected loadEditor() {
    const displayedId = this.id?.replace(/[.]+/g, '-');
    const editorSelector = `#${displayedId} .full-json-editor`;
    const editor = document.querySelector(editorSelector);
    if (this.editorView) {
      this.editorView.destroy();
    }
    const linterExtension = linter(jsonParseLinter());
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.editorView = new EditorView({
      doc: this.editedConfigStr,
      extensions: [
        basicSetup,
        lintGutter(),
        autocompletion({ override: [self.completion] }),
        linterExtension,
        json(),
        EditorView.updateListener.of((e) => {
          this.jsonOk = diagnosticCount(e.state) <= 0;
        }),
      ],
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parent: editor!,
    });
  }

  import() {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e: Event) => {
      const file = (e as any).target.files[0];
      if (
        window.confirm(`Replace current config by the one in ${file.name}?`)
      ) {
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = (readerEvent) => {
          const content = (readerEvent as any).target.result; // this is the content!
          this.editedConfigStr = content;
          this.loadEditor();
        };
      }
    };
    input.click();
  }

  fetchPredefined($event: MatButtonToggleChange) {
    if (this.filter === $event.value) {
      this.filter = '';
    } else {
      this.filter = $event.value;
    }
    this.fetch();
  }
}
