import { Component, Input } from '@angular/core';
import { ArnAuthConfig } from '@arianee/arn-client';
import { ArnConnectorType } from '@arianee/arn-types';

@Component({
  selector: 'arn-config-client-auth',
  templateUrl: './arn-config-client-auth.component.html',
  styleUrls: ['./arn-config-client-auth.component.scss'],
})
export class ArnConfigClientAuthComponent {
  @Input()
  config: ArnAuthConfig | undefined;

  ConnectorType: string[] = [];

  constructor() {
    for (const connectorType in ArnConnectorType) {
      if (connectorType != ArnConnectorType.AAT) {
        this.ConnectorType.push(connectorType);
      }
    }
  }
}
