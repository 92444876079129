export enum ArnLogLevel {
  info = 'info',
  debug = 'debug',
  warn = 'warn',
  error = 'error',
}

export interface ArnValidLogConfig {
  name: string;
  logLevels: ArnLogLevel[];
}
