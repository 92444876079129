<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title><code>arn_data</code></mat-panel-title>
    <mat-panel-description>{{admin!.context.messages.project.data.label}}</mat-panel-description>
  </mat-expansion-panel-header>
  <div class="predefined-filters">
    <mat-button-toggle-group [(ngModel)]="predefinedFilter" (change)="fetchPredefined($event)"
      aria-label="Predefined filter">
      <mat-button-toggle *ngFor="let predefined of predefinedFilters"
        [value]="predefined.filter">{{predefined.label}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-form-field appearance="fill">
    <mat-label>{{admin!.context.messages.project.data.filter}}</mat-label>
    <input matInput type="search" [(ngModel)]="filter" (change)="fetch()">
  </mat-form-field>
  <button mat-button color="primary" (click)="fetch()" align="end"
    aria-label="Fetch">{{admin!.context.messages.project.data.fetch}}
  </button>
  <div class="full-json-editor"></div>
  <p class="error" *ngIf="dataError">{{dataError}}</p>
  <div mat-dialog-actions align="end">
    <button mat-button color="primary" [disabled]="updating || !jsonOk" (click)="update()" align="right"
      aria-label="Update data">
      <span *ngIf="updating">
        <mat-spinner [diameter]="22"></mat-spinner>
        Updating...
      </span> <span *ngIf="!updating">
       {{admin!.context.messages.project.data.update}}
      </span>
    </button>
  </div>
</mat-expansion-panel>
