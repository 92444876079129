import { AdminFileService } from './AdminFileService';
import { AdminJsonFileReader } from './json/AdminJsonFileReader';
import { ArnDataReader } from './ArnDataReader';
import { AdminJsonFileWriter } from './json/AdminJsonFileWriter';
import { ArnDataWriter } from './ArnDataWriter';
import { ArnLogger } from '@arianee/arn-types';

export class WebFileService implements AdminFileService {
  constructor(protected logger: ArnLogger) {}

  createJsonFileReader<T>(_fileName: string): AdminJsonFileReader<T> {
    throw new Error('Not implemented');
  }

  createCsvFileReader(
    _fileName: string,
    _columns: string[],
    _csvSeparator: string | undefined
  ): ArnDataReader<any> {
    throw new Error('Not implemented');
  }

  createJsonFileWriter<T>(_fileName: string): AdminJsonFileWriter<T> {
    throw new Error('Not implemented');
  }

  createCsvFileWriter(
    _fileName: string,
    _csvSeparator: string | undefined
  ): ArnDataWriter<any> {
    throw new Error('Not implemented');
  }
}
