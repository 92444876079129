import { AdminFileService } from './service/file/AdminFileService';
import { HttpService } from './HttpService';
import { AdminServiceFactory } from './service/AdminServiceFactory';

export class ArnAdminServices {
  protected http: HttpService | undefined;
  private file: AdminFileService | undefined;

  constructor(protected factory: AdminServiceFactory) {}

  getHttp(): HttpService {
    if (!this.http) {
      this.http = this.factory.createHttp();
    }
    return this.http;
  }

  getFile(): AdminFileService {
    if (!this.file) {
      this.file = this.factory.createFile();
    }
    return this.file;
  }
}
