import { ArnAdminContext } from './ArnAdminContext';

export class RootConfigService {
  constructor(protected context: ArnAdminContext) {}

  async create(projectKey: string, config: object): Promise<any> {
    this.context.logger.info('Creating', projectKey);
    return this.context.service.getHttp().requestPost(projectKey, config);
  }

  /**
   * Look for project keys.
   *
   * @param projectKeyPattern
   */
  find(projectKeyPattern: string): Promise<string[]> {
    return this.context.service
      .getHttp()
      .request(`?q=${projectKeyPattern}`, {});
  }
}
