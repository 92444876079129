<mat-expansion-panel #panel [disabled]="!! error" collapsedHeight="*" expandedHeight="*" hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-icon aria-hidden="false" aria-label="ARN Server" [class]="panel.expanded ? 'material-icons' :
      'material-icons-outlined'" fontIcon="dns"></mat-icon>
      <code class="server-url">{{server.admin.context.config.serverUrl}}</code>
    </mat-panel-title>
    <mat-panel-description>
      <span *ngIf="!error">
        <span *ngIf="projects">
          {{ server.admin.context.messages.server.projects(projects.length)}}
        </span>
        <span *ngIf="!projects">
          <mat-spinner [diameter]="22"></mat-spinner>{{server.admin.context.messages.server.projectsLoading}}
        </span>
      </span>
      <span *ngIf="error" class="error" (click)="projectsChanged()">{{error}}</span>
    </mat-panel-description>
    <button mat-icon-button [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation();" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button [cdkCopyToClipboard]="server.admin.context.config.serverUrl" mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Copy URL" fontIcon="content_copy"
        ></mat-icon>
        Copy URL
      </button>
      <button [cdkCopyToClipboard]="server.admin.context.config.apiKey" mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Copy API Key" fontIcon="content_copy"
        ></mat-icon>
        Copy API Key
      </button>
      <button (click)="createProject()" mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Create project" fontIcon="add"></mat-icon>
        Create project...
      </button>
      <button (click)="remove()" mat-menu-item>
        <mat-icon aria-hidden="false" aria-label="Remove server" fontIcon="close"></mat-icon>
        Remove
      </button>
    </mat-menu>
  </mat-expansion-panel-header>
  <div *ngIf="projects">
    <mat-accordion *ngFor="let project of projects">
      <arn-project [server]="server" [project]="project" (deleted)="projectsChanged()"></arn-project>
    </mat-accordion>
  </div>
</mat-expansion-panel>
