import { UrlUtil } from './UrlUtil';

export class AdminObjectUtil {
  static set<T>(to: T, path: string, value: any) {
    const paths = path.split('.');
    if (paths.length > 0) {
      const prop = paths[0];
      const subPath = paths.splice(1).join('.');
      if (subPath) {
        AdminObjectUtil.set((to as any)[prop], subPath, value);
      } else if (prop) {
        (to as any)[prop] = value;
      } else {
        Object.keys(to as any).forEach((p) => delete (to as any)[p]);
        Object.assign(to as any, value);
      }
    } else {
      (to as any)[path] = value;
    }
  }

  static dotPathFrom(basePath: string, subPath: string): string {
    let fullPath = UrlUtil.join(basePath, subPath).replace(/\//g, '.');
    if (fullPath.startsWith('.')) {
      fullPath = fullPath.substring(1);
    }
    return fullPath;
  }
}
