import { Component, Input } from '@angular/core';
import { ArnProjectValidConfigurationValue } from '@arianee/arn-admin-client';

@Component({
  selector: 'arn-config-details',
  templateUrl: './arn-config-details.component.html',
  styleUrls: ['./arn-config-details.component.scss'],
})
export class ArnConfigDetailsComponent {
  @Input()
  config: ArnProjectValidConfigurationValue | undefined;
}
