import { ArnConnectorOptions, ArnConnectorType } from '@arianee/arn-types';

export enum ArnConnectionStatus {
  disconnected = 'disconnected',

  /**
   * Attempting to identify wallet address.
   */
  connecting = 'connecting',

  /**
   * Wallet address is known
   */
  connected = 'connected',

  /**
   * Connected + attempting to prove wallet ownership.
   */
  signing = 'signing',

  /**
   * Connected + signed
   */
  authenticated = 'authenticated',
}

/**
 * ARN auth context status.
 */
export interface ArnAuthStatus {
  connectionStatus: ArnConnectionStatus;

  /**
   * Wallet ID.
   */
  address: string | undefined;

  message?: string;
  signature?: string;

  /**
   * Arianee Access Token, if set message and signature are ignored.
   */
  aat?: string;

  connectorType: ArnConnectorType;
  connectorOpts: ArnConnectorOptions;
}
