import { ArnAdminContext } from '../../ArnAdminContext';
import { ArnProjectValidConfigurationValue } from './ArnProjectConfiguration';

export class ProjectConfigService {
  constructor(
    protected context: ArnAdminContext,
    protected projectKey: string
  ) {}

  async delete(): Promise<void> {
    await this.context.service.getHttp().requestDelete(this.projectKey);
  }

  async get(): Promise<ArnProjectValidConfigurationValue> {
    return this.context.service.getHttp().request(this.projectKey, {});
  }

  async create(value: object): Promise<any> {
    return this.context.service.getHttp().requestPost(this.projectKey, value);
  }

  async update(value: object): Promise<any> {
    return this.context.service.getHttp().requestPut(this.projectKey, value);
  }
}
