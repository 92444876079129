<h1 mat-dialog-title>{{formData.messages.title}}</h1>
<form name="addServerForm" mat-dialog-content>
  <div>
    <code>
      <mat-form-field appearance="fill">
        <mat-label>{{formData.messages.serverUrl.label}}</mat-label>
        <input required type="url" matInput [formControl]="serverUrl" [(ngModel)]="formData.serverUrl" cdkFocusInitial>
        <mat-error *ngIf="serverUrl.invalid">{{serverUrlError()}}</mat-error>
      </mat-form-field>
    </code>
  </div>
  <div>
    <code>
      <mat-form-field appearance="fill">
        <mat-label>{{formData.messages.apiKey.label}}</mat-label>
        <input required type="password" matInput [formControl]="apiKey" [type]="hideApiKey ? 'password' :
        'text'" [(ngModel)]="formData.apiKey">
        <button mat-icon-button matSuffix (click)="hideApiKey = !hideApiKey" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideApiKey">
          <mat-icon>{{hideApiKey ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="apiKey.invalid">{{apiKeyError()}}</mat-error>
      </mat-form-field>
    </code>
  </div>
</form>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">{{formData.messages.cancel.label}}</button>
  <button mat-button color="primary" [disabled]="serverUrl.invalid || apiKey.invalid"
    [mat-dialog-close]="formData">{{formData.messages.add.label}}</button>
</div>
