import { Component } from '@angular/core';
import {
  ArnAdminConfig,
  ArnAdminContext,
  ArnAdminServices,
  ArnAdminToolMessages,
  ArnAdminToolMessages_en,
  ArnServerAdmin,
  MessagesLoader,
  WebAdminServiceFactory,
} from '@arianee/arn-admin-client';
import { ConsoleLogger } from '@arianee/arn-client';
import { ArnServerInfo } from './server/ArnServerInfo';
import {
  ArnServerAddComponent,
  ArnServerAddInfo,
} from './server/add/arn-server-add.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArnLogger, ArnLogLevel } from '@arianee/arn-types';

interface ArnAdminInfo {
  servers: ArnServerAddInfo[];
}

@Component({
  selector: 'arn-admin',
  templateUrl: './arn-admin.component.html',
  styleUrls: ['./arn-admin.component.scss'],
})
export class ArnAdminComponent {
  protected static readonly storageKey = 'arn-admin';
  servers: ArnServerInfo[] = [];
  displayedServers: ArnServerInfo[] = [];
  filter = '';
  protected logLevel = ArnLogLevel.info;
  protected logger: ArnLogger = new ConsoleLogger({
    name: 'arn-admin',
    console,
    logLevels: [this.logLevel],
  });
  protected messages: ArnAdminToolMessages = new ArnAdminToolMessages_en();
  protected server: ArnServerInfo | undefined;

  constructor(protected dialog: MatDialog, protected snackBar: MatSnackBar) {
    new MessagesLoader().get(navigator.language).then((messages) => {
      this.messages = messages;
      const item = localStorage.getItem(ArnAdminComponent.storageKey);
      if (item) {
        const arnAdmin: ArnAdminInfo = JSON.parse(item);
        const serversInfo = arnAdmin?.servers || [];
        for (const serverInfo of serversInfo) {
          this.addServer(
            serverInfo.serverUrl,
            serverInfo.apiKey,
            serverInfo.projectKey
          );
        }
      }
    });
  }

  refresh() {
    this.servers.sort((a, b) =>
      a.admin.context.config.serverUrl.localeCompare(
        b.admin.context.config.serverUrl
      )
    );
    this.displayedServers = this.servers.filter(
      (server) =>
        server.admin.context.config.serverUrl.indexOf(this.filter) >= 0
    );
  }

  save() {
    const arnAdmin: ArnAdminInfo = {
      servers: this.servers.map((server) => {
        const config = server.admin.context.config;
        return {
          serverUrl: config.serverUrl,
          apiKey: config.apiKey,
          projectKey: config.defaultProject || '.*',
          messages: this.messages.root.addServer.dialog,
        };
      }),
    };
    localStorage.setItem(
      ArnAdminComponent.storageKey,
      JSON.stringify(arnAdmin)
    );
  }

  addServerClicked() {
    const dialogRef = this.dialog.open(ArnServerAddComponent, {
      data: {
        serverUrl: '',
        apiKey: '',
        projectKey: '.*',
        messages: this.messages.root.addServer.dialog,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          await this.addServer(result.serverUrl, result.apiKey, undefined);
          this.save();
        } catch (e) {
          const err = e as Error;
          this.snackBar.open(err.message, 'Dismiss', { duration: 3000 });
        }
      }
    });
  }

  protected async addServer(
    serverUrl: string,
    apiKey: string,
    projectKey: string | undefined
  ) {
    if (
      this.servers.find((s) => s.admin.context.config.serverUrl === serverUrl)
    ) {
      throw Error(`Server with ${serverUrl} already added`);
    }
    const admin = this.createServerAdmin(serverUrl, apiKey, projectKey);
    const server: ArnServerInfo = {
      admin,
    };
    this.servers.push(server);
    this.refresh();
  }

  protected createServerAdmin(
    serverUrl: string,
    apiKey: string,
    projectKey: string | undefined
  ): ArnServerAdmin {
    const serverConfig: ArnAdminConfig = {
      serverUrl,
      apiKey,
      projectKey,
      logLevel: this.logLevel,
      lang: Intl.DateTimeFormat().resolvedOptions().locale.substring(0, 2),
    } as any;
    if (projectKey && projectKey != '.*') {
      serverConfig.defaultProject = projectKey;
    }
    const factory = new WebAdminServiceFactory(serverConfig, this.logger);
    const services = new ArnAdminServices(factory);
    const context = new ArnAdminContext(
      this.logger,
      serverConfig,
      this.messages.root,
      services
    );
    return new ArnServerAdmin(context);
  }

  removeServer(toRemove: ArnServerInfo) {
    this.servers = this.servers.filter((s) => s !== toRemove);
    this.save();
  }

  projectFound($event: { serverUrl: string; projectKey: string }) {
    const server = this.servers.find(
      (s) => s.admin.context.config.serverUrl === $event.serverUrl
    );
    if (server) {
      server.admin.context.config.defaultProject = $event.projectKey;
      this.save();
    } else {
      throw Error(`Could not find server with URL "${$event.serverUrl}"`);
    }
  }
}
