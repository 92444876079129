import { Messages } from './Messages';
import { ArnAdminToolMessages } from './ArnAdminToolMessages';

export class MessagesLoader implements Messages {
  protected loaded: { [lang: string]: ArnAdminToolMessages } = {};

  async get(someLang: string): Promise<ArnAdminToolMessages> {
    const lang = someLang.substring(0, 2);
    let msg = this.loaded[lang];
    if (!msg) {
      switch (lang) {
        case 'fr':
          {
            const { default: FrenchMessages } = await import(
              './ArnAdminToolMessages_fr'
            );
            msg = new FrenchMessages();
          }
          break;
        default:
        case 'en':
          {
            const { default: EnglishMessages } = await import(
              './ArnAdminToolMessages_en'
            );
            msg = new EnglishMessages();
          }
          break;
      }
    }
    return msg;
  }
}
