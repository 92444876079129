import { ArnProjectAdmin } from './project';
import { RootConfigService } from './RootConfigService';
import { ArnAdminContext } from './ArnAdminContext';
import { ArnLogLevel } from '@arianee/arn-types';

export interface ArnAdminConfig {
  serverUrl: string;
  apiKey: string;
  defaultProject?: string;
  logLevel?: ArnLogLevel;
  lang: string;
}

export class ArnServerAdmin {
  protected configService: RootConfigService;

  constructor(readonly context: ArnAdminContext) {
    this.configService = new RootConfigService(context);
  }

  /**
   * Get the admin API to administrate an existing project in this server.
   *
   * @param projectKey The name/key of the existing project to administrate.
   */
  project(projectKey: string): ArnProjectAdmin {
    return new ArnProjectAdmin(this.context, projectKey);
  }

  async createProject(projectKey: string, config: object) {
    return this.configService.create(projectKey, config);
  }

  async list(projectKeyPattern: string): Promise<string[]> {
    return this.configService.find(projectKeyPattern);
  }

  async migrate(migration: string, filter = '') {
    const allProjectKeys = await this.list(filter);
    for (const projectKey of allProjectKeys) {
      await this.project(projectKey).migrate(migration);
    }
  }
}
