import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ArnServerInfo } from './ArnServerInfo';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ArnProjectInfo } from './project/ArnProjectInfo';
import { AdminNotFound, AdminUnauthorized } from '@arianee/arn-admin-client';

@Component({
  selector: 'arn-server',
  templateUrl: './arn-server.component.html',
  styleUrls: ['./arn-server.component.scss'],
  viewProviders: [MatExpansionPanel],
})
export class ArnServerComponent implements OnInit {
  @Input()
  server!: ArnServerInfo;

  @Output()
  readonly removed = new EventEmitter<void>();

  @Output()
  readonly projectFound = new EventEmitter<{
    serverUrl: string;
    projectKey: string;
  }>();

  @ViewChild('panel', { static: true }) panel?: MatExpansionPanel;

  projects: ArnProjectInfo[] | undefined;

  error?: string;

  remove() {
    if (
      window.confirm(
        `Remove ${this.server.admin.context.config.serverUrl} from the list?`
      )
    ) {
      this.removed.emit();
    }
  }

  async ngOnInit() {
    const projectKey = this.server.admin.context.config.defaultProject;
    this.projects = projectKey ? [{ projectKey }] : await this.loadProjects();
  }

  protected async loadProjects() {
    const admin = this.server.admin;
    try {
      const projectKeys = await admin.list('.*');
      return projectKeys.map((projectKey: string) => ({ projectKey }));
    } catch (e) {
      if (e instanceof AdminUnauthorized) {
        const serverUrl = admin.context.config.serverUrl;
        const projectKey = window.prompt(
          `This API key does not allow to list all projects on server ${serverUrl}. Please specify the name/key of the project you want to administrate:`
        );
        if (projectKey) {
          const projectAdmin = admin.project(projectKey);
          try {
            await projectAdmin.get(); // Check if the project can be accessed
            this.error = undefined;
            this.projectFound.emit({ serverUrl, projectKey });
            return [{ projectKey }];
          } catch (e) {
            if (e instanceof AdminNotFound) {
              this.error = `Project "${projectKey}" not found in this server`;
            } else {
              this.error = (e as Error).toString();
            }
          }
        } else {
          this.error = 'Project name/key is missing';
        }
      } else {
        this.error = (e as Error).message;
      }
      return [];
    }
  }

  /**
   * Create a new project in the server.
   */
  async createProject() {
    if (this.server) {
      const answer = window.prompt('Which key to identify the new project?');
      const projectKey = answer?.trim();
      if (projectKey) {
        const found = this.projects?.find((p) => p.projectKey === projectKey);
        if (!found) {
          await this.server.admin.createProject(projectKey, {});
          await this.projectsChanged();
        } else {
          window.alert(
            `Project key "${projectKey}" is already used in this server`
          );
        }
      }
    }
  }

  async projectsChanged() {
    this.projects = await this.loadProjects();
  }
}
