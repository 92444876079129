import { ArnAdminConfig } from './ArnServerAdmin';
import { ArnAdminServices } from './ArnAdminServices';
import { ArnLogger } from '@arianee/arn-types';
import { ArnAdminMessages } from './service';

export class ArnAdminContext {
  constructor(
    readonly logger: ArnLogger,
    readonly config: ArnAdminConfig,
    public messages: ArnAdminMessages,
    readonly service: ArnAdminServices
  ) {}
}
