import { ArnDataReader, ArnDataWriter } from '../../service';
import { DataMigrator } from './migration/DataMigrator';
import { ArnAdminContext } from '../../ArnAdminContext';
import { UrlUtil } from '../../util/UrlUtil';

export class ArnDataAdmin {
  constructor(readonly context: ArnAdminContext, readonly projectKey: string) {}

  async import(
    dataPath: string,
    dataReader: ArnDataReader<any>,
    overwrite: boolean,
    migrationName: string | null
  ) {
    const logger = this.context.logger;
    const filesData = new Map<string, any>();
    {
      let migratedData;
      const data = await dataReader.read();
      if (migrationName) {
        const migrator = new DataMigrator(logger);
        migratedData = await migrator.migrate(migrationName, data);
        migratedData.forEach((out) => {
          let fileData = filesData.get(out.output);
          if (!fileData) {
            fileData = [];
            filesData.set(out.output, fileData);
          }
          fileData.push(out.data);
        });
      } else {
        filesData.set(dataPath, data);
      }
    }
    for (const [p, data] of Array.from(filesData)) {
      const path = p.length === 1 ? p[0] : p;
      logger.info('Saving', path, 'to', data);
      if (path.startsWith('file:') || path.startsWith('./')) {
        const fileWriter = this.context.service
          .getFile()
          .createJsonFileWriter(path);
        const okToWrite = await fileWriter.save(data, overwrite);
        if (okToWrite) {
          logger.info('Data written in', path);
        }
      } else {
        const remotePath =
          UrlUtil.join('data', dataPath) + (overwrite ? '?overwrite=true' : '');
        await this.context.service
          .getHttp()
          .requestPost(UrlUtil.join(this.projectKey, remotePath), data);
      }
    }
  }

  async export(dataPath: string, dataWriter: ArnDataWriter<any>) {
    const urlPath = `${this.projectKey}/data/${dataPath}`;
    const data = await this.context.service.getHttp().request(urlPath, {});
    dataWriter.writeAll(data as any[]);
  }

  async importFile(
    fileName: string,
    columns: string | null,
    csvSeparator: string | null,
    dataPath: string,
    overwrite: boolean,
    migration: string | null
  ) {
    const ext = UrlUtil.extname(fileName);
    let dataReader: ArnDataReader<any>;
    switch (ext) {
      case '.csv': {
        dataReader = this.context.service
          .getFile()
          .createCsvFileReader(
            fileName,
            (columns || '').split(','),
            csvSeparator || undefined
          );
        break;
      }
      case '.json': {
        dataReader = this.context.service
          .getFile()
          .createJsonFileReader(fileName);
        break;
      }
      default:
        throw Error(`No file reader for extension ${ext}`);
    }
    return this.import(dataPath, dataReader, overwrite, migration);
  }

  async exportFile(
    fileName: string,
    columns: string | null,
    csvSeparator: string | null,
    dataPath: string,
    overwrite: boolean
  ) {
    const logger = this.context.logger;
    const ext = UrlUtil.extname(fileName);
    let dataWriter: ArnDataWriter<any>;
    switch (ext) {
      case '.csv': {
        dataWriter = this.context.service
          .getFile()
          .createCsvFileWriter(fileName, csvSeparator || undefined);
        break;
      }
      default:
        throw Error(`No file reader for extension ${ext}`);
    }
    logger.info('Exporting', dataPath, 'to', fileName);
    return this.export(dataPath, dataWriter);
  }
}
