import { ConfigMigration } from './ConfigMigration';
import { ProjectConfigService } from '../ProjectConfigService';
import { ArnAdminContext } from '../../../ArnAdminContext';

export class ProjectMigrator {
  constructor(
    protected context: ArnAdminContext,
    protected configService: ProjectConfigService
  ) {}

  async migrate(migration: string): Promise<void> {
    const { default: Command } = await import(migration);
    const migrationCommand = new Command(
      this.context.service.getHttp(),
      this.context.logger
    ) as ConfigMigration;
    await migrationCommand.execute(this.configService);
  }
}
