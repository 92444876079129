import {
  ArnAdminAddServerMessages,
  ArnAdminProjectMessages,
  ArnAdminToolMessages,
} from './ArnAdminToolMessages';

const project: ArnAdminProjectMessages = {
  label: 'Project',
  delete: {
    confirm: (project: string, server: string) =>
      'Delete project "' + project + '" from ' + server + '?',
    confirmed: (project: string) => 'Project "' + project + '" deleted.',
  },
  config: {
    label: 'Project configuration',
    filter: 'Filter',
    predefinedFilters: [
      { filter: '', label: 'All' },
      { filter: 'client/auth', label: 'Client auth' },
      { filter: 'auth', label: 'Server auth' },
      { filter: 'data', label: 'Data storage' },
      { filter: 'i18n', label: 'Translations storage' },
      { filter: 'condition', label: 'Conditions storage' },
      { filter: 'nmp', label: 'NMP' },
      { filter: 'spkz', label: 'SPKZ' },
      { filter: 'track', label: 'Tracked clients' },
    ],
    fetch: 'Fetch',
    update: 'Update',
    updated: 'Project configuration updated',
  },
  data: {
    label: 'Project data',
    filter: 'Filter',
    predefinedFilters: [
      { filter: '', label: 'All' },
      { filter: 'features', label: 'Feature flags' },
      { filter: 'i18n', label: 'Translations' },
      { filter: 'condition', label: 'Conditions' },
    ],
    fetch: 'Fetch',
    update: 'Update',
    updated: 'Project data updated',
  },
};

const addServer: ArnAdminAddServerMessages = {
  button: {
    label: 'Add a server',
  },
  dialog: {
    title: 'Add an ARN Server',
    serverUrl: {
      label: 'Server URL',
      whenEmpty:
        'Please provide the URL of the ARN Server you want to administrate',
      whenInvalid: 'Not a valid URL',
    },
    apiKey: {
      label: 'API key',
      whenEmpty: 'You cannot administrate without a project or root API Key',
      whenInvalid: 'Invalid API key',
    },
    add: { label: 'Add' },
    cancel: { label: 'Cancel' },
  },
};

export class ArnAdminToolMessages_en implements ArnAdminToolMessages {
  root = {
    addServer,
    filterServerUrl: 'Filter URL',
    project,
    servers: (n: number, total: number) =>
      n <= 0
        ? ''
        : n +
          '/' +
          (total < 1 ? '' : total === 1 ? '1 server' : total + ' servers'),
    server: {
      projects: (n: number) =>
        n < 1 ? 'No project' : n === 1 ? '1 project' : n + ' projects',
      projectsLoading: 'Projects loading...',
    },
  };
}

export default ArnAdminToolMessages_en;
