import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ArnAdminAddServerDialogMessages } from '@arianee/arn-admin-client';

export interface ArnServerAddInfo {
  serverUrl: string;
  apiKey: string;
  messages: ArnAdminAddServerDialogMessages;
  projectKey?: string;
}

@Component({
  selector: 'arn-server-create',
  templateUrl: 'arn-server-add.component.html',
  styleUrls: ['./arn-server-add.component.scss'],
})
export class ArnServerAddComponent {
  hideApiKey = true;
  serverUrl = new FormControl('', [
    Validators.required,
    Validators.pattern(
      '(?:^|\\s)((https?:\\/\\/)?(?:localhost|[\\w-]+(?:\\.[\\w-]+)+)(:\\d+)?(\\/\\S*)?)'
    ),
  ]);
  apiKey = new FormControl('', [Validators.required]);

  constructor(
    public dialogRef: MatDialogRef<ArnServerAddComponent>,
    @Inject(MAT_DIALOG_DATA) public formData: ArnServerAddInfo
  ) {}

  serverUrlError() {
    return this.serverUrl.hasError('required')
      ? this.formData.messages.serverUrl.whenEmpty
      : this.formData.messages.serverUrl.whenInvalid;
  }

  apiKeyError() {
    return this.apiKey.hasError('required')
      ? this.formData.messages.apiKey.whenEmpty
      : this.formData.messages.apiKey.whenInvalid;
  }

  onCancel() {
    this.dialogRef.close();
  }
}
