import { AdminServiceFactory } from './AdminServiceFactory';
import { AdminFileService } from './file/AdminFileService';
import { AdminHttpService } from '../AdminHttpService';
import { HttpService } from '../HttpService';
import { ArnAdminConfig } from '../ArnServerAdmin';
import { WebFileService } from './file/WebFileService';
import { ArnLogger } from '@arianee/arn-types';

export class WebAdminServiceFactory implements AdminServiceFactory {
  constructor(protected config: ArnAdminConfig, protected logger: ArnLogger) {}

  createFile(): AdminFileService {
    return new WebFileService(this.logger);
  }

  createHttp(): HttpService {
    return new AdminHttpService(this.config, this.logger);
  }
}
