import { Component, Input } from '@angular/core';
import { ArnValidClientConfig } from '@arianee/arn-types';

@Component({
  selector: 'arn-config-client',
  templateUrl: './arn-config-client.component.html',
  styleUrls: ['./arn-config-client.component.scss'],
})
export class ArnConfigClientComponent {
  @Input()
  config: ArnValidClientConfig | undefined;
}
