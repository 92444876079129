import {
  ArnLogger,
  ArnLogLevel,
  ArnLogMethod,
  ArnValidLogConfig,
  NOP,
} from '@arianee/arn-types';

export interface ConsoleLogConfig extends ArnValidLogConfig {
  console: LogConsole;
}

export interface LogConsole {
  log(...data: any[]): void;

  debug(...data: any[]): void;

  warn(...data: any[]): void;

  error(...data: any[]): void;
}

export class ConsoleLogger implements ArnLogger {
  readonly name: string;
  readonly info: ArnLogMethod;
  readonly debug: ArnLogMethod;
  readonly warn: ArnLogMethod;
  readonly error: ArnLogMethod;

  constructor(protected config: ConsoleLogConfig) {
    const { name, logLevels, console } = this.config;
    this.name = name;
    this.info = logLevels.includes(ArnLogLevel.info)
      ? (...data: any[]) => console.log(name + ':', ...data)
      : NOP;
    this.debug = logLevels.includes(ArnLogLevel.debug)
      ? (...data: any[]) => console.debug(name + ':', ...data)
      : NOP;
    this.warn = logLevels.includes(ArnLogLevel.warn)
      ? (...data: any[]) => console.warn(name + ':', ...data)
      : NOP;
    this.error = logLevels.includes(ArnLogLevel.error)
      ? (...data: any[]) => console.error(name + ':', ...data)
      : NOP;
  }

  fork(subName: string, nest = true): ArnLogger {
    if (this.config.logLevels.length <= 0) {
      return this; // Optimization: don't bother to create instances if all logs are disabled.
    }
    return new (class extends ConsoleLogger {})({
      ...this.config,
      name: nest ? `${this.name}:${subName}` : subName,
    });
  }
}
