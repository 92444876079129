import jwtDecode from 'jwt-decode';
import { ObjectUtil } from '../util';
import { ArnError } from '../ArnError';
import { JWT } from './Jwt';
import { ethers } from 'ethers';
import { hashMessage } from 'ethers';

export interface ArianeeJWT extends JWT {
  chain: string;
  scope: ArnScope;
  subId: number;
}

export interface AnyArianeeJWT extends JWT {
  [key: string]: any;
}

export enum ArnTokenSubject {
  certificate = 'certificate',
}

export enum ArnScope {
  all = 'all',
}

export class Chain {
  static readonly ID_POA_Sokol = 77;

  /**
   * POA testnet
   */
  static readonly ID_POA_Core = 99;
  private static ID_Polygon_Main = 137;

  constructor(readonly name: string, readonly id?: number) {}

  static fromName(name: string): Chain | undefined {
    return Chain.chains.find((c) => c.name === name);
  }

  static readonly chains = [
    new Chain('ethereum', 1),
    new Chain('testnet', Chain.ID_POA_Sokol),
    new Chain('mainnet', Chain.ID_POA_Core),
    new Chain('polygon', Chain.ID_Polygon_Main),
    new Chain('ysl', Chain.ID_Polygon_Main),
  ];
}

export class ArnToken {
  constructor(
    /**
     * Wallet address ('0x4967f96A952096Cb1802D375cB70314f8729A977' for instance)
     */
    readonly address: string,
    readonly subject: ArnTokenSubject,
    readonly issuedAt: Date,
    readonly expiresAt: Date,
    readonly scope: ArnScope,
    readonly chain: Chain,
    readonly nftId: number
  ) {}

  static fromString(aat: string): ArnToken {
    const jwt = jwtDecode<ArianeeJWT>(aat);
    const address = ObjectUtil.asSet(jwt.iss);
    const issuedAt = new Date(ObjectUtil.asSet(jwt.iat));
    const expiresAt = new Date(ObjectUtil.asSet(jwt.exp));
    const chainName = ObjectUtil.asSet(jwt.chain);
    const scope = jwt.scope;
    const chain = ObjectUtil.asSet(
      Chain.fromName(chainName),
      () => new ArnError(`Unknown chain: ${chainName}`)
    );
    const nftId = ObjectUtil.asSet(jwt.subId);
    return new ArnToken(
      address,
      jwt.sub as ArnTokenSubject,
      issuedAt,
      expiresAt,
      scope,
      chain,
      nftId
    );
  }

  static validate(aat: string): {
    valid: boolean;
    arnToken?: ArnToken;
    error?: string;
  } {
    let header,
      payload,
      signature = null;
    const aatSplit = aat.split('.');
    if (aatSplit.length === 3) {
      [header, payload, signature] = aatSplit;
    }
    if (!header || !payload || !signature) {
      return { valid: false, error: 'Invalid token' };
    }
    const arnToken = ArnToken.fromString(aat);
    if (arnToken?.expiresAt && arnToken.expiresAt.getTime() < Date.now()) {
      return { valid: false, arnToken, error: 'Token expired' };
    }
    const headerPayload = `${header}.${payload}`;
    const recoverAddress = ethers.verifyMessage(
      headerPayload,
      signature
    );
    if (
      arnToken?.address &&
      arnToken.address.toLowerCase() !== recoverAddress.toLocaleLowerCase()
    ) {
      return { valid: false, arnToken, error: 'Invalid signature' };
    }
    return { valid: true, arnToken };
  }
}
