export class AssertionError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class ObjectUtil {
  static isUndefined(obj: any) {
    return obj === void 0;
  }

  static isSet(obj?: any): boolean {
    return !this.isNotSet(obj);
  }

  static isNotSet(obj: any): boolean {
    return ObjectUtil.isUndefined(obj) || obj === null;
  }

  /**
   * Return an object as assumed to be set (not `null` nor `undefined`).
   * If it is not, an exception will be thrown.
   *
   * @param obj The value to test.
   * @param unsetError The creation method for the error to be throw if obj is not set (an `AssertionError` by default).
   */
  static asSet<T>(
    obj?: T | null,
    unsetError = () => new AssertionError('value is not set')
  ): T {
    if (this.isNotSet(obj)) {
      throw unsetError();
    }
    return obj!;
  }

  static find(
    from: Record<string, any>,
    path: string
  ): { value: any; from: Record<string, any>; prop: string } {
    if (!path) {
      return { value: from, from, prop: '' };
    }
    const dot = path.indexOf('.');
    const propEnd = dot > 0 ? dot : path.length;
    const prop = path.substring(0, propEnd);
    const value = from[prop];
    const subPath = path.substring(propEnd + 1);
    return subPath.length > 0
      ? ObjectUtil.find(value, subPath)
      : { value, from, prop };
  }
}
