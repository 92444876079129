import { ArnDataAdmin } from './data';
import { ProjectMigrator } from './config/migration/ProjectMigrator';
import { ProjectConfigService } from './config/ProjectConfigService';
import { ArnAdminContext } from '../ArnAdminContext';
import { AdminJsonFileReader } from '../service/file/json/AdminJsonFileReader';
import { ArnProjectValidConfigurationValue } from './config';

export class ArnProjectAdmin {
  protected configService: ProjectConfigService;

  constructor(readonly context: ArnAdminContext, readonly projectKey: string) {
    this.configService = new ProjectConfigService(context, projectKey);
  }

  async get(): Promise<ArnProjectValidConfigurationValue> {
    return this.configService.get();
  }

  /**
   * Remove that project's configuration from the server database.
   */
  async delete() {
    return this.configService.delete();
  }

  /**
   * Get the API to administrate project's data.
   */
  data(): ArnDataAdmin {
    return new ArnDataAdmin(this.context, this.projectKey);
  }

  async import(dataReader: AdminJsonFileReader<any>, overwrite: boolean) {
    const data = await dataReader.read();
    return this.set(data, overwrite);
  }

  async set<T extends object = any>(data: T, overwrite: boolean) {
    return this.context.service
      .getHttp()
      .requestPut(this.projectKey + (overwrite ? '?overwrite=true' : ''), data);
  }

  async importFile(fileName: string, overwrite: boolean) {
    const dataReader = this.context.service
      .getFile()
      .createJsonFileReader(fileName);
    await this.import(dataReader, overwrite);
  }

  async migrate(migration: string) {
    const migrator = new ProjectMigrator(this.context, this.configService);
    return migrator.migrate(migration);
  }
}
