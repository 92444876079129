import { HttpService } from './HttpService';
import { ArnAdminConfig } from './ArnServerAdmin';
import { UrlUtil } from './util/UrlUtil';
import { ArnLogger } from '@arianee/arn-types';

export class AdminUnauthorized extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class AdminNotFound extends Error {
  constructor(message: string) {
    super(message);
  }
}

/**
 * Issues administration HTTP requests to an ARN Server.
 */
export class AdminHttpService implements HttpService {
  constructor(
    protected config: ArnAdminConfig,
    protected readonly logger: ArnLogger
  ) {}

  async request<T = any>(urlPath: string, init: RequestInit): Promise<T> {
    init.headers = init.headers || {};
    (init.headers as any)['x-api-key'] = this.config.apiKey;
    (init.headers as any)['Cache-Control'] = 'no-cache';
    const url = UrlUtil.join(this.config.serverUrl, urlPath);
    this.logger.debug(url, init);
    const response = await fetch(url, init);
    let content: T;
    if (response.headers.get('Content-Type')?.startsWith('application/json')) {
      content = await response.json();
    } else {
      content = (await response.text()) as T;
    }
    this.logger.debug('response is', content);
    if (!response.ok) {
      const message = `Could not fetch from ${url}: ${response.statusText} (${
        response.status
      }): ${(content as any)?.message}`;
      switch (response.status) {
        case 401:
          throw new AdminUnauthorized(message);
        case 404:
          throw new AdminNotFound(message);
        default:
          throw new Error(message);
      }
    }
    return content;
  }

  async requestPost<T extends object = object>(
    path: string,
    payload: object
  ): Promise<T> {
    return this.request(path, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }

  async requestDelete<T extends object = object>(path: string): Promise<T> {
    return this.request(path, { method: 'DELETE' });
  }

  async requestPut<T extends object = object>(
    path: string,
    payload: object
  ): Promise<T> {
    return this.request(path, {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(payload),
    });
  }
}
