export class NopStorage implements Storage {
  [name: string]: any;

  get length(): number {
    return 0;
  }

  clear(): void {
    // NOP
  }

  getItem(_key: string): string | null {
    return null;
  }

  key(_index: number): string | null {
    return null;
  }

  removeItem(_key: string): void {
    // NOP
  }

  setItem(_key: string, _value: string): void {
    // NOP
  }
}
